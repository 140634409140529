
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import Header from '@/components/Header.vue';
import JobFamilies from '@/components/JobFamilies.vue';
import ContentBanner from '@/components/ContentBanner.vue';
import Footer from '@/components/Footer.vue';
import { TakeQuestionnaireContent } from '@/content/JobSectorContent';
import PlainContent from '@/types/PlainContent';
import JobsService from '@/components/JobsService.vue';

export default defineComponent({
  name: 'JobSector',
  components: {
    Header,
    JobFamilies,
    ContentBanner,
    Footer,
    JobsService,
  },
  setup() {
    const takeQuestionnaireContent: PlainContent = TakeQuestionnaireContent;
    const route = useRoute();
    const sector = computed(() => route.params.sector);

    return {
      takeQuestionnaireContent,
      sector,
    };
  },
});
