
import { defineComponent, ref, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { JobFamily } from '@/types/JobSectors';

export default defineComponent({
  name: 'Carousel',
  props: {
    families: {
      required: true,
      type: Array as PropType<JobFamily[]>,
    },
  },
  setup(props) {
    const current = ref(0);
    const router = useRouter();

    if (router.currentRoute.value.query.current) {
      current.value = Number(router.currentRoute.value.query.current) - 1;
    }

    const prev = () => {
      current.value -= 1;
      if (current.value < 0) {
        current.value = props.families.length - 1;
      }
    };

    const next = () => {
      current.value += 1;
      if (current.value > props.families.length - 1) {
        current.value = 0;
      }
    };

    const addZeroToCurrent = () => {
      if (current.value + 1 < 10) {
        return `0${current.value + 1}`;
      }
      return current.value + 1;
    };

    const addZeroToLength = () => {
      if (props.families.length < 10) {
        return `0${props.families.length}`;
      }
      return props.families.length;
    };

    return {
      current,
      prev,
      next,
      addZeroToCurrent,
      addZeroToLength,
    };
  },
});
