
import { defineComponent, computed, PropType } from 'vue';
import Carousel from './Carousel.vue';
import { JobSectors } from '@/content/JobSectorContent';
import { JobSectorType } from '@/types/JobSectors';

export default defineComponent({
  name: 'JobFamilies',
  components: {
    Carousel,
  },
  props: {
    sector: {
      type: String as PropType<JobSectorType>,
      required: true,
    },
  },
  setup(props) {
    const filteredJobSectors = computed(() => JobSectors[props.sector]);
    return {
      filteredJobSectors,
    };
  },
});
