import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ecaff8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "content-container" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.filteredJobSectors.intro.title), 1),
        _createElementVNode("div", {
          class: "body",
          innerHTML: _ctx.filteredJobSectors.intro.body
        }, null, 8, _hoisted_3)
      ]),
      _createVNode(_component_Carousel, {
        families: _ctx.filteredJobSectors.families
      }, null, 8, ["families"])
    ])
  ]))
}